export default function App() {
  return (
    <div className="flex h-screen items-center justify-center bg-black text-white">
      <div className="text-center">
        <h1 className="text-4xl font-bold animate-bounce">pişir.com.tr</h1>
        <p className="text-xl mt-4 animate-pulse">Yapım Aşamasında</p>
      </div>
    </div>
  );
}
